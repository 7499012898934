// responsive breakpoints
@mixin breakpoint($class) {
    @if $class == 'phone' {
        @media only screen and (max-width: 767px){
            @content;
        }
    } 

    @else if $class == 'table' {
        @media only screen and (min-width: 768px){
            @content;
        }
    }

    @else if $class == 'desktop'{
        @media screen and (min-width: 1200px){
            @content;
        }
    }

    @else{
        @warn "Breakpoint mixin supports: phone, table, desktop";
    }
}

// typography
@mixin type ($tag, $breakpoint, $weight: null) {
    // h1
    @if $tag == 'h1'{
        @if $breakpoint == 'phone' {
            font-size: 2.25rem;
            line-height: 2.7rem;
        }
        @else if $breakpoint == 'table'{
            font-size: 4.5rem;
            line-height: 5.4rem;
        }
    }

    // h2
    @if $tag == 'h2'{
        @if $breakpoint == 'phone' {
            font-size: 2rem;
            line-height: 2.4rem;
        }
        @else if $breakpoint == 'table'{
            font-size: 3rem;
            line-height: 3.6rem;
        }
    }

    // h3
    @if $tag == 'h3' {
        @if $breakpoint == 'phone' {
            font-size: 1.4rem;
            line-height: 1.5rem;
        }
        @else if $breakpoint == 'table'{
            font-size: 1.7rem;
            line-height: 1.8rem;
        }
    }

    // h4 - paragraph
    @if $tag == 'p' {
        @if $breakpoint == 'phone' {
            font-size: 18px;
            line-height: 21.6px;
        }
        @else if $breakpoint == 'table'{
            font-size: 20px;
            line-height: 24px;
        }
    }

    // h5
    @if $tag == 'h5' {
        @if $breakpoint == 'phone' {
            font-size: 13px;
            line-height: 15.6px;
        }
        @else if $breakpoint == 'table'{
            font-size: 15px;
            line-height: 18px;
        }
    }

    @if $weight != null {
        font-weight: $weight;
    }
}