.intro{
    // height: 100vh;
    margin: 10rem 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content{
        padding: 0 3rem;
        max-width: 1200px;

        @include breakpoint ('table') {
            padding: 0 8rem;
        }
    }

    &__info{
        display: flex;
    }

    &__header{
        text-transform: uppercase;
        font-family: 'MADE Bon Voyage', sans-serif;
        @include type('h1', 'phone', 100);

        @include breakpoint ('table') {
            @include type ('h1', 'table', 100);
        }
    }

    &__role,
    &__developer{
        justify-content: flex-start;
    }

    &__designer,
    &__vancouver{
        justify-content: flex-end;
    }

    &__location{
        justify-content: center;
    }

    &__arrow{
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
}