*, *:before, *:after {
    box-sizing: border-box;
}

*{
    margin: 0;
    padding: 0;
    // cursor: none;
}

html{
    scroll-behavior: smooth;
}

body{
    font-family: 'Helvetica Neue', sans-serif;
    color: $text-color;
    overflow-x: hidden;
    width:100%;
    height: 100%;
    background-image: url(../../assets/images/noise.png);
    animation: grain 1s steps(10) infinite;
    background-position: center;
}

// scrollbar design
body::-webkit-scrollbar {
    width: 15px;               
}

body::-webkit-scrollbar-track {
    background: $grey;        
}

body::-webkit-scrollbar-thumb {
    background-color: $blood-orange;    
    border-radius: 20px;       
    border: 3px solid $grey;  
}

p {
    @include type ('p', 'phone');

    @include breakpoint ('table') {
        @include type ('p', 'table');
    }
}

a {
    text-decoration: none;
    color: $text-color;
}

.heading{
    text-transform: uppercase;
    font-family: 'MADE Bon Voyage', sans-serif;
    margin-bottom: 1rem;
    @include type('h1', 'phone');

    @include breakpoint ('table') {
        margin-bottom: 2rem;
        @include type ('h1', 'table');
    }
}

.row{
    width: 100%;
    display: flex;

    &__start{
        display: initial;
    }

    &__end{
        margin-top: 1.5rem;

        @include breakpoint ('table') {
            margin-top: 3rem;
            display: flex;
        }
    }
}

.rightarrow{
    width: 10%;
    margin-left: 0.8rem;

    @include breakpoint ('table') {
        width: initial;
    }

    &--project{
        width: 5%;
        margin-left: 0.8rem;

        @include breakpoint ('table') {
            width: 8%;
        }
    }
}

// .noise{
//     background-image: url(../../assets/images/noise.png);
//     animation: grain 1s steps(10) infinite;
//     // height: 100vh;
// }

@keyframes grain {
    0%{ background-position: 0 0; }
    100%{ background-position: 100% 100%; }
}

/* text selected*/
::selection{
    background: $blood-orange;
    color: $white;
}