.contact{
  padding: 2rem 0;

  @include breakpoint ('table') {
      padding: 4rem 0;
  }

  &__loop{
    display: flex;
    white-space: nowrap;
    margin: 2rem 0 3rem 0;

    @include breakpoint ('phone'){
      display: none;
    }

    &-text{
      animation: textLoop 10s linear infinite;
      padding-right: .35em;
      @include type('h3', 'phone');

      @include breakpoint ('table') {
          @include type('h3', 'table'); 
      }
    }
  }

  &__info{
    @include breakpoint ('table') {
      display: flex;
      align-items: center;
    }

    &--left,
    --right{
      @include breakpoint ('table') {
        width: 50%;
      }
    }

    &-text{
      @include type('p', 'phone');
      margin-bottom: 1rem;

      @include breakpoint ('table') {
        @include type('p', 'table'); 
      }
    }
  }

  &__infoItem{
    display: flex;
    margin-bottom: 1rem;
    @include type('h2', 'phone'); 

    & > img{
      transition: transform 0.3s ease;
      transform: translateX(0px);
    }

    &:hover{
        & > img{
            transform: translateX(20px);
        }
    }

      @include breakpoint ('table') {
          @include type('h2', 'table'); 
      }
  }
}

@keyframes textLoop {
  0% {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0)
  }
  100% {
      -webkit-transform: translate3d(calc(100vw + 100%), 0, 0);
      transform: translate3d(calc(0vw - 100%), 0, 0)
  }
}