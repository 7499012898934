.header{

    &__content{
        display: flex;
        flex-direction: row;
        min-height: 60px;
        padding: 1.25rem 1.5rem;

        @include breakpoint ('table') {
            padding: 2rem 6.5rem;
        }
    }

    &__left{
        display: flex;
        align-items: center;

        @include breakpoint ('table') {
            flex: 1;
        }
    }

    &__logo{
        color: $text-color;
        @include type ('h3', 'phone', 500);

        @include breakpoint ('table') {
            @include type ('h3', 'table', 500);
        }
    }

    &__right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        width: 100%;
    }

    &__nav-item{
        margin: 0 0.5rem;
        @include type ('h3', 'phone', 500);

        @include breakpoint ('table') {
            @include type ('h3', 'table', 500);
        }
    }
}