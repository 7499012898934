.about{
    padding: 2rem 0;
    max-width: 1600px;
    margin: 0 auto;

    @include breakpoint ('table') {
        padding: 4rem 0;
    }

    &__info{
        width: 100%;
        @include type('p', 'phone');

        @include breakpoint ('table') {
            width: 50%;
            @include type ('p', 'table');
        }

        &--end{
            @include breakpoint ('table') {
                width: 50%;
                justify-content: flex-end;
            }
        }
    }

    &__arrow{
        display: none;

        @include breakpoint ('table') {
            display: initial;
            align-self: flex-end;
            width: 50%;
        }
    }
}