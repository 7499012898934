.container {
    width: 100%;
    padding: 0 1.5rem;
    margin: 0 auto;
    max-width: 1200px;

    @include breakpoint ('table'){
        padding: 0 8rem;
    }

    // @include breakpoint ('desktop'){
    //     padding: 0 18rem;
    // }
}