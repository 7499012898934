@font-face{
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeueThin.ttf') format('truetype');
    font-style: normal;
    font-weight:400;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeueLight.ttf') format('truetype');
    font-style: normal;
    font-weight: 500;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeueMedium.ttf') format('truetype');
    font-style: normal;
    font-weight: 600;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeueBold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
}

@font-face{
    font-family: 'Helvetica Neue';
    src: url('../../fonts/HelveticaNeueBlackCond.ttf') format('truetype');
    font-style: normal;
    font-weight: 800;
}

@font-face{
    font-family: 'MADE Bon Voyage';
    src: url('../../fonts/MADEBonVoyage.otf') format('opentype');
    font-style: normal;
    font-weight: 400;
}