.project{
    padding: 2rem 0;

    &__list{
        &--right{
            @include breakpoint ('table') {
                margin-left: 50%;
            }
        }
    }

    &__listItem{
        margin: 0.5rem 0;

        & > img{
            transition: transform 0.3s ease;
            transform: translateX(0px);
        }

        &:hover{
            & > img{
                transform: translateX(20px);
            }
        }
    }

    &__type{
        @include type('h5', 'phone'); 

        @include breakpoint ('table') {
            @include type('h5', 'table'); 
        }
    }

    &__title{
        @include type('h2', 'phone'); 
        display: flex;
        align-items: center;

        @include breakpoint ('table') {
            @include type('h2', 'table'); 
        }

        &-container{
            display: flex;

            & > img{
                transition: transform 0.3s ease;
                transform: translateX(0px);
            }
    
            &:hover{
                & > img{
                    transform: translateX(20px);
                }
            }
        }
    }

    // project detail page style
    &-detail{
        max-width: 1500px;
        margin: 0 auto;
        
        &__content{
            @include breakpoint ('table'){
                display: flex;
            }

            &-wrapper{
                padding: 1.25rem 1.5rem;

                // @include breakpoint ('table') {
                //     padding: 2rem 6.5rem;
                // }
            }

            &--bottom{
                margin-top: 1.5rem;
            }
        }

        &__container{
            &--left{
                @include breakpoint ('table'){
                    width: 40%;
                }
            }

            &--right{
                @include breakpoint ('table'){
                    width: 60%;
                }
            }
        }

        &__list-item{
            margin-bottom: 1rem;

            &--link{
                margin-top: 1.5rem;
            }
        }

        &__category{
            @include type('h3', 'phone', 400); 
            
            @include breakpoint ('table') {
                @include type('h3', 'table', 400); 
            }
        }

        &__title{
            @include type('h1', 'phone', 500); 
            // font-family: 'MADE Bon Voyage', sans-serif;

            @include breakpoint ('table') {
                @include type('h1', 'table', 500); 
            }
        }

        &__year,
        &__tool,
        &__link{
            @include type('p', 'phone'); 

            @include breakpoint ('table') {
                @include type('p', 'table'); 
            }
        }

        &__image,
        &__video{
            width: 100%;
            height: auto;
        }

        &__info{
            &-title{
                font-family: 'MADE Bon Voyage', sans-serif;
                margin: 1rem 0 0.5rem 0;
                @include type('h3', 'phone', 400); 
            
                @include breakpoint ('table') {
                    @include type('h3', 'table', 400); 
                }
            }

            &-container{
                margin: 1.5rem 0 3rem 0;
            }
        }

    }
}